import env from "../env";
const BEACON_SDK = env.debug
  ? "https://cdn.stationa.com/js/beacon-dev.min.js"
  : "https://cdn.stationa.com/js/bcn.js";

// prettier-ignore
(function(a,b,c,e){a[c]=a[c]||function(){a[c].q=a[c].q||[];a[c].q.push(arguments)};
var d=b.createElement(e);b=b.getElementsByTagName(e)[0];d.async=!0;
d.src=BEACON_SDK;
b.parentNode.insertBefore(d,b)})(window,document,"beacon","script");

export function updateCredentials(creds) {
  window.beacon("update-credentials", creds);
}

/**
 * Initializes Beacon SDK
 */
export function initBeacon(options) {
  window.beacon("init", options);
  // ensure that GTM has also set up
  window.dataLayer = window.dataLayer || [];
}
// Dynamically computes the default, out-of-the-box intent context
const defaultContext = () => {
  const ctx = {
    url: window.location.href,
  };
  return ctx;
};

// Helper function to create and collect new intents
export const collectIntent = (resource, action, extraContext) => {
  const actionContext = { ...defaultContext(), ...(extraContext || {}) };
  return window.beacon("collect", resource, action, actionContext);
};

function makeRedirectUrl(url) {
  return `${env.beaconApiBaseUrl}/redirect?url=${encodeURIComponent(url)}`;
}

export default {
  portfolioLocationNoteCreate(portfolioId, locationId) {
    collectIntent(
      `/portfolios/${portfolioId}/location/${locationId}/note`,
      "create",
    );
    window.dataLayer.push({
      event: "webapp-portfolio-createNote",
      portfolioId,
      locationId,
    });
  },
  rfiView(rfpId, rfiId) {
    collectIntent(`/rfps/${rfpId}/rfis/${rfiId}`, "view");
  },
  rfpQuestionView(rfpId, questionId) {
    collectIntent(`/rfps/${rfpId}/questions/${questionId}`, "view");
  },
  redirect(url) {
    window.location.href = makeRedirectUrl(url);
  },
  rfpDisclosuresOpen(rfpId) {
    // This is a misnomer, because we want to keep our metrics consistent with past disclosures downloads
    window.dataLayer.push({ event: "rfi-download", rfpId });
    collectIntent(`/rfps/${rfpId}`, "download");
  },
  rfpPreviewFormView(rfpId) {
    window.dataLayer.push({ event: "preview-form-view", rfpId });
    collectIntent(`/rfps/${rfpId}/preview-form`, "view");
  },
  rfpSetView(rfpId, oldContent, newContent) {
    collectIntent(`/rfps/${rfpId}`, "set_view", {
      oldContent,
      newContent,
    });
  },
  rfpView(rfpId) {
    collectIntent(`/rfps/${rfpId}`, "view");
  },
  rfpLoadMoreLocations(rfpId, numVisibleRfpLocations) {
    collectIntent(`/rfps/${rfpId}/locations`, "set_view", {
      numVisibleRfpLocations,
    });
  },
  rfiCreate(rfpId) {
    window.dataLayer.push({ event: "rfi-create", rfpId });
    collectIntent(`/rfps/${rfpId}/rfis`, "create");
  },
  rfiFormSetView(open, rfpId) {
    collectIntent(`/rfps/${rfpId}/rfis`, "set_view", {
      open,
      rfpId,
    });
  },
  providerProfileView(providerId, me, isSuper) {
    collectIntent(`/providers/${providerId}`, "view", {
      me,
      isSuper,
    });
  },
  providerProfileUpdate(providerId, key, value, me, isSuper) {
    collectIntent(`/providers/${providerId}`, "update", {
      key,
      value,
      me,
      isSuper,
    });
  },
  buyerProfileView(buyerId, me, isSuper) {
    collectIntent(`/buyers/${buyerId}`, "view", {
      me,
      isSuper,
    });
  },
  buyerProfileUpdate(buyerId, key, value, me, isSuper) {
    collectIntent(`/buyers/${buyerId}`, "update", {
      key,
      value,
      me,
      isSuper,
    });
  },
  /* Proposal submission intents */
  proposalCreate(providerId, rfpId) {
    window.dataLayer.push({
      event: "proposal-create",
      rfpId,
      providerId,
    });
    collectIntent(`/proposals`, "create", {
      providerId,
      rfpId,
    });
  },
  // Proposal page
  formResponseCreate(proposalId, formId) {
    collectIntent(`/proposals/${proposalId}/form_responses`, "create", {
      formId,
    });
  },
  formResponseDelete(proposalId, formId, formResponseId) {
    collectIntent(
      `/proposals/${proposalId}/form_responses/${formResponseId}`,
      "delete",
      {
        formId,
      },
    );
  },
  commentSubmit(proposalId, formResponseId, target, commentText) {
    collectIntent(
      `/proposals/${proposalId}/form_responses/${formResponseId}/comments`,
      "create",
      {
        target,
        commentText,
      },
    );
  },
  fixingStatusUpdate(proposalId, formResponseId, newStatus) {
    collectIntent(
      `/proposals/${proposalId}/form_responses/${formResponseId}/status`,
      "update",
      {
        newStatus,
      },
    );
  },
  proposalReviewOpen(proposalId, open) {
    collectIntent(`/proposals/${proposalId}/review`, "set_view", {
      open,
    });
  },
  proposalSubmit(proposalId) {
    collectIntent(`/proposals/${proposalId}`, "update", {
      submitted: true,
    });
  },
  // Form page
  formResponseUpdate(proposalId, formId, target, value, isFixing) {
    collectIntent(`/proposals/${proposalId}/forms/${formId}`, "update", {
      target,
      value,
      isFixing,
    });
  },
  formResponseAttachmentUpload(proposalId, formId, questionId, attachment) {
    collectIntent(`/proposals/${proposalId}/forms/${formId}`, "upload", {
      questionId,
      attachment,
    });
  },
  formResponseAttachmentDownload(proposalId, formId, questionId, attachmentId) {
    collectIntent(`/proposals/${proposalId}/forms/${formId}`, "download", {
      questionId,
      attachmentId,
    });
  },
  formResponseAttachmentDelete(proposalId, formId, questionId, attachmentId) {
    collectIntent(`/proposals/${proposalId}/forms/${formId}`, "delete", {
      questionId,
      attachmentId,
    });
  },
  /* Proposal intents end */
  shareLink(ref) {
    collectIntent(ref, "share", {
      method: "link",
    });
  },
  mapTranslate(oldState, newState) {
    collectIntent("/map", "set_view", {
      oldState,
      newState,
    });
  },
  mapSetView(oldView, newView) {
    collectIntent("/map", "set_view", {
      oldView,
      newView,
    });
  },
  query(ref, q, extraContext) {
    window.dataLayer.push({ event: "query", q });
    collectIntent(ref, "query", {
      q,
      ...(extraContext || {}),
    });
  },
  transition(ref, target, extraContext) {
    collectIntent(ref, "transition", {
      target,
      ...(extraContext || {}),
    });
  },
  portfolioDownload(portfolioId, numLocations) {
    collectIntent(`/portfolios/${portfolioId}`, "download", {
      num_locations: numLocations,
    });
  },
  portfolioStarUpdate(portfolioId, locationId, addingStar) {
    collectIntent(`/portfolios/${portfolioId}`, "update", {
      locationId,
      addingStar,
    });
    window.dataLayer.push({
      event: "webapp-portfolio-star",
      portfolioId,
      locationId,
    });
  },
  portfolioShowUpdate(portfolioId, locationId, showing) {
    collectIntent(`/portfolios/${portfolioId}`, "update", {
      locationId,
      showing,
    });
    window.dataLayer.push({
      event: "webapp-portfolio-show",
      portfolioId,
      locationId,
    });
  },
  portfolioLocationNoteOpen(portfolioId, locationId) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}/note`,
      "view",
    );
    window.dataLayer.push({
      event: "webapp-portfolio-viewNote",
      portfolioId,
      locationId,
    });
  },
  portfolioImageOpen(portfolioId, locationId) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}`,
      "view",
      {
        type: "image",
      },
    );
    window.dataLayer.push({
      event: "webapp-portfolio-viewImage",
      portfolioId,
      locationId,
    });
  },

  portfolioLabelLocationUpdate(portfolioId, locationId, label) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}`,
      "update",
      label,
    );
    window.dataLayer.push({
      event: "webapp-portfolio-labelLocation",
      portfolioId,
      locationId,
      label,
    });
  },

  portfolioLabelLocationDelete(portfolioId, locationId, label) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}`,
      "delete",
      label,
    );
    window.dataLayer.push({
      event: "webapp-portfolio-labelLocationDelete",
      portfolioId,
      locationId,
      label,
    });
  },
  portfolioLocationStatusUpdate(portfolioId, locationId, status) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}`,
      "update",
      status,
    );
    window.dataLayer.push({
      event: "webapp-portfolio-locationStatusUpdate",
      portfolioId,
      locationId,
      status,
    });
  },

  portfolioLocationStatusDelete(portfolioId, locationId) {
    collectIntent(
      `/portfolios/${portfolioId}/locations/${locationId}`,
      "delete",
    );
    window.dataLayer.push({
      event: "webapp-portfolio-locationStatusDelete",
      portfolioId,
      locationId,
    });
  },
};
