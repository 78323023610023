import { computed, watch } from "vue";
import { useColorMode } from "@vueuse/core";
import { useAuth } from "./useAuth";

type Theme = "dark" | "light" | "auto";

const LS_KEY = "__sta_theme";
// NOTE: We may want to consider defaulting this to "auto" so that the default behavior is to use
// the user's preferred device settings. This is currently not done as it would change the current
// behavior of the app
const DEFAULT_THEME: Theme = "dark";
const BLUE = "#33cadd";
const GREEN = "#45ca8c";

/**
 * Composable for interactiing with reactive theme selection, backed by localStorage for storing
 * client-side preferences
 */
export function useTheme() {
  const { isProvider } = useAuth();
  const primaryColor = computed(() => (isProvider.value ? BLUE : GREEN));
  const theme = useColorMode({
    initialValue: DEFAULT_THEME,
    storageKey: LS_KEY,
  });

  const dark = computed(() => theme.value === "dark");
  const light = computed(() => !dark.value);

  const backgroundColor = computed(() => (dark.value ? "#111" : "#eee"));

  function setTheme(newTheme: Theme) {
    theme.value = newTheme;
  }

  function toggleTheme() {
    theme.value = dark.value ? "light" : "dark";
  }

  /*
   * WARNING: This means we are coordinating theme across vuetify classes
   * (the class is called `dark`), localStorage, and now Tailwind.
   * We need this because using `prefix: 'tw-'` and `darkMode: 'class'`
   * in tailwind.config.js expects the wrapping html element class to
   * also be prefixed (aka. `tw-dark`).
   */
  watch(
    dark,
    () => {
      if (dark.value) {
        document.documentElement.dataset.theme = "dark";
      } else {
        document.documentElement.dataset.theme = "light";
      }
    },
    { immediate: true },
  );

  return {
    dark,
    light,
    primaryColor,
    backgroundColor,
    theme,
    setTheme,
    toggleTheme,
  };
}
