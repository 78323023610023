<template>
  <TDialog :model-value="true" persistent>
    <template #content>
      <loading-state-card
        v-if="loading"
        icon="manage_accounts"
        card-title="We're setting up your account"
        dialog
      />
      <t-card v-else dialog>
        <t-card-title>
          <t-icon left>manage_accounts</t-icon>
          <h2 class="tw-heading">Select your organization type</h2>
        </t-card-title>
        <t-card-text class="tw-text-base tw-pt-0">
          Before we start, tell us a bit more about yourself so we can customize
          your Station A experience!
        </t-card-text>
        <t-card-text>
          <t-card color="green">
            <t-card-title>
              <t-icon left>location_city</t-icon>
              <h2 class="tw-heading">Do you buy clean energy?</h2>
            </t-card-title>
            <t-card-text class="tw-text-base tw-pt-0">
              Are you looking to buy clean energy? Station A saves you time,
              money, and the headache of finding the best provider for your
              energy and sustainability needs.
            </t-card-text>
            <t-card-actions class="tw-justify-end">
              <TBtn @click="selectBuyer" class="!tw-text-current">
                I am a buyer
              </TBtn>
            </t-card-actions>
          </t-card>
          <t-card color="blue" class="tw-mt-4">
            <t-card-title>
              <t-icon left>construction</t-icon>
              <h2 class="tw-heading">Do you sell clean energy?</h2>
            </t-card-title>
            <t-card-text class="tw-text-base tw-pt-0">
              Do you develop, finance, or build clean energy? Station A can help
              you explore market trends, find new projects, and connect with
              buyers.
            </t-card-text>
            <t-card-actions class="tw-justify-end">
              <TBtn @click="selectProvider" class="!tw-text-current">
                I am a provider
              </TBtn>
            </t-card-actions>
          </t-card>
        </t-card-text>
      </t-card>
    </template>
  </TDialog>
</template>

<script>
import { useTokenRefresher } from "../lib/useTokenRefresher";
import { useAuth } from "../lib/useAuth";
import LoadingStateCard from "@/components/LoadingStateCard.vue";

export default {
  name: "OrgTypeSelectDialog",
  components: {
    LoadingStateCard,
  },
  setup() {
    const { refreshToken } = useTokenRefresher();
    const { organizationId } = useAuth();
    return { refreshToken, organizationId };
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    autoSelect: {
      type: String,
    },
  },
  async created() {
    if (this.autoSelect) {
      if (this.autoSelect === "buyer") {
        await this.selectBuyer();
      } else if (this.autoSelect === "provider") {
        await this.selectProvider();
      }
    }
  },
  methods: {
    async getOrgId() {
      // Check for an edge case where there is an org but no buyer/provider
      if (this.organizationId) {
        return this.organizationId;
      } else {
        const newOrganization = await this.$api.Organization.create();
        return newOrganization.id;
      }
    },
    async selectBuyer() {
      this.loading = true;
      try {
        const organizationId = await this.getOrgId();
        await this.$api.Organization.createBuyer(organizationId);
        // Note: this emit event needs to happen before refreshToken, or else
        // this event might not reach the parent
        this.$emit("select", "buyer");
        await this.refreshToken();
      } finally {
        this.loading = false;
      }
    },
    async selectProvider() {
      this.loading = true;
      try {
        const organizationId = await this.getOrgId();
        await this.$api.Organization.createProvider(organizationId);
        // Note: this emit event needs to happen before refreshToken, or else
        // this event might not reach the parent
        this.$emit("select", "provider");
        await this.refreshToken();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
