import { createApp, watchEffect } from "vue";
import { createHead } from "@unhead/vue";

import env from "./env";
import { initBeacon, updateCredentials } from "./lib/beacon";
import { getClient, setClientOptions } from "./lib/client";
import { fakeLatency } from "./lib/fakery";
import { debug } from "./lib/logging";
import { updateSentryContext, useSentry } from "./lib/sentry";

import { useAccessToken } from "./lib/useAccessToken";
import { useAccountSummary } from "./lib/useAccountSummary";
import { useAuth } from "./lib/useAuth";
import { useImpersonation } from "./lib/useImpersonation";
import { usePeriodicTokenRefresh } from "./lib/usePeriodicTokenRefresh";
import { useToast } from "./lib/useToast";
import { useTokenRefresher, TokenRefresher } from "./lib/useTokenRefresher";

import "@material-symbols/font-400";
import "./styles.css";

import API from "./plugins/API";
import Beacon from "./plugins/Beacon";
import Env from "./plugins/env";
import Formatting from "./plugins/Formatting";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);
const head = createHead();

app.use(head);
app.use(router);

// Sets up token refresh
const initialRefresh = (async () => {
  // Don't refresh access tokens in local dev
  if (env.devMode) {
    return;
  }

  const { setTokenRefreshFn, onTokenRefresh } = useTokenRefresher();
  const { clearAccessToken, setAccessToken } = useAccessToken();
  const { startPeriodicRefresh, onPeriodicRefreshStopped } =
    usePeriodicTokenRefresh();

  const tokenRefresher = new TokenRefresher({
    baseURL: env.authBaseUrl,
    timeout: 10000,
  });
  setTokenRefreshFn(() => tokenRefresher.refreshToken());

  // Set up our event hooks
  onTokenRefresh(setAccessToken);
  onPeriodicRefreshStopped(clearAccessToken);

  // Finally kick off the periodic refresh
  if (!(await startPeriodicRefresh())) {
    // If the initial refresh fails, clear the local access token state which might otherwise refer
    // to some stale local cookie
    clearAccessToken();
  }
})();

// Sets up API clients
(() => {
  const client = getClient();
  const { accessToken } = useAccessToken();
  const { reload } = useAccountSummary();

  setClientOptions({
    baseURL: env.apiBaseUrl,
    timeout: 60000,
  });

  if (env.devMode) {
    if (env.fakeApiLatency) {
      debug(`Injecting fake API latency for testing (${env.fakeApiLatency}ms)`);
      const client = getClient();
      client.interceptors.request.use(async function (config) {
        // TODO: Why do we need the ! operator here? Is Typescript drunk?
        await fakeLatency(env.fakeApiLatency!);
        return config;
      });
    }

    if (env.fakeApiErrorRate) {
      debug(`Faking random API errors for testing`);
      const client = getClient();
      client.interceptors.response.use(function (response) {
        if (Math.random() <= env.fakeApiErrorRate!) {
          throw new Error("Fake API error");
        }
        return response;
      });
    }

    if (env.impersonate) {
      debug(`Impersonating account for testing (${env.impersonate})`);
      const { impersonate } = useImpersonation();
      impersonate(env.impersonate);
    }
  }

  // Set up global reactivity hooks for access token changes
  watchEffect(() => {
    if (accessToken.value) {
      setClientOptions({
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
        },
      });
      // make sure the account is reloaded when the access token has changed
      // i.e. the user has logged in
      reload();
    }
  });

  const { pushToast } = useToast();

  client.interceptors.response.use(undefined, (error) => {
    if (error.code === "ERR_NETWORK") {
      pushToast(
        {
          level: "error",
          message:
            "It looks like something went wrong. Please try again later.",
          persistent: true,
        },
        "connection-error",
      );
    }
    return Promise.reject(error);
  });

  app.use(API);
})();

// Sentry integration
(() => {
  const { accountId, groups, userType } = useAuth();

  useSentry(router, {
    app,
    dsn: env.sentryDsn,
    release: env.version,
    environment: env.environment,
    replaysSessionSampleRate: env.debug ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
    enableTracing: true,
    tracePropagationTargets: [env.apiBaseUrl],
  });

  // Sets up watcher for updating Sentry user context
  watchEffect(() => {
    updateSentryContext({
      accountId: accountId.value,
      groups: groups.value,
      userType: userType.value,
    });
  });
})();

// Hubspot integration
(() => {
  const { summary } = useAccountSummary();
  // have to set up a watch effect because the summary might not be
  // loaded the first time we run this
  watchEffect(() => {
    window._hsq = window._hsq || [];
    if (summary.value) {
      // if the user is logged in, tell hubspot who they are
      window._hsq.push(["identify", { email: summary.value.email }]);
    }
  });
})();

// Beacon integration
(() => {
  const { accessToken } = useAccessToken();
  const beaconOpts = {
    app: {
      id: env.beaconAppId,
      version: env.version,
      environment: env.environment,
    },
    clientConfig: {
      debug: env.debug,
      enabled: !env.devMode,
      baseURL: env.beaconApiBaseUrl,
    },
  };
  initBeacon(beaconOpts);
  app.use(Beacon);

  // Sets up watcher for updating Beacon creds
  watchEffect(() => {
    updateCredentials(accessToken.value);
  });
})();

// Other misc. first-party Vue plugins
app.use(Env);
app.use(Formatting);

initialRefresh.then(() => {
  app.mount("#app");
});
