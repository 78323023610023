<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  color?: "purple" | "green" | "yellow" | "blue";
  dialog?: boolean; // This is used when we know this card is used as the contents of a dialog
}>();

const dynamicClasses = computed(() => {
  const classes = [];
  if (props.color === "yellow") {
    classes.push(
      "tw-bg-yellow-300",
      "dark:!tw-bg-yellow-700",
      "!tw-text-yellow-800",
      "dark:!tw-text-yellow-100",
    );
  } else if (props.color === "green") {
    classes.push(
      "!tw-bg-success-500/50",
      "!tw-text-success-800",
      "dark:!tw-text-success-100",
    );
  } else if (props.color === "blue") {
    classes.push(
      "!tw-bg-provider-500/50",
      "!tw-text-provider-800",
      "dark:!tw-text-provider-100",
    );
  } else if (props.color === "purple") {
    classes.push(
      "!tw-bg-purple-500/50",
      "tw-text-purple-900",
      "dark:tw-text-purple-200",
    );
  } else {
    classes.push("tw-bg-white", "dark:tw-bg-zinc-800");
  }
  if (props.dialog) {
    classes.push(
      "tw-place-self-center",
      "max-sm:tw-h-full",
      "tw-max-h-full",
      "tw-overflow-scroll",
      "tw-w-full",
      "sm:!tw-max-w-3xl",
    );
  }
  return classes;
});
</script>
<template>
  <div class="tw-rounded-xl" :class="dynamicClasses">
    <slot />
  </div>
</template>
