import { ref, watch } from "vue";
import { createGlobalState } from "@vueuse/core";

import { getClient } from "./client";
import { useAccessToken } from "./useAccessToken";
import { useAuth } from "./useAuth";

type ImpersonateAPIResponse = {
  logged_in: boolean;
  is_active: boolean;
  account_id: string;
  access_token: string;
};

export const useImpersonation = createGlobalState(function () {
  const client = getClient();
  const { clearAccessToken, setAccessToken } = useAccessToken();
  const { accountId: currentAccountId } = useAuth();
  const impersonatedAccountId = ref(currentAccountId.value);

  async function doImpersonate() {
    if (!impersonatedAccountId.value) {
      return;
    }

    const { status, data } = await client.get<ImpersonateAPIResponse>(
      "/auth/impersonate",
      {
        params: { account_id: impersonatedAccountId.value },
      },
    );
    if (status === 200) {
      const accessToken = data.access_token;
      setAccessToken(accessToken);
    }
  }

  watch(impersonatedAccountId, () => doImpersonate());

  function impersonate(newAccountId: string) {
    impersonatedAccountId.value = newAccountId;
  }

  function clearImpersonation() {
    clearAccessToken();
    impersonatedAccountId.value = null;
  }

  return {
    impersonate,
    clearImpersonation,
    impersonatedAccountId,
  };
});
