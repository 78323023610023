<script setup lang="ts">
import { computed } from "vue";
import { RouteLocationRaw } from "vue-router";
import { RouterLinkProps } from "vue-router";

const props = withDefaults(
  defineProps<{
    pill?: boolean;
    circle?: boolean;
    disabled?: boolean;
    href?: string;
    to?: RouteLocationRaw;
    routerLinkProps?: Partial<RouterLinkProps>;
    target?: string;
    flat?: boolean;
    titleCase?: boolean;
    loading?: boolean;
    small?: boolean;
    large?: boolean;
    dense?: boolean;
    wrap?: boolean;
    color?: "green" | "gray" | "red" | "orange" | "purple";
  }>(),
  {
    flat: true,
  },
);

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void;
}>();

const dynamicClasses = computed(() => {
  const classes = [];
  if (props.disabled) {
    classes.push("tw-opacity-30", "tw-pointer-events-none");
  }

  if (props.loading) {
    classes.push("tw-animate-pulse");
  }

  if (props.pill) {
    classes.push("!tw-rounded-full");
  }

  if (props.circle) {
    classes.push("!tw-rounded-full", "tw-aspect-square", "!tw-p-2");
  }

  if (props.flat) {
    classes.push(
      "hover:tw-bg-black/10",
      "active:tw-bg-black/20",
      "dark:hover:tw-bg-white/10",
      "dark:active:tw-bg-white/25",
    );
  }

  if (props.color === "green") {
    classes.push(
      "tw-bg-success-300/70",
      "!tw-text-success-700",
      "hover:!tw-bg-success-300",
      "active:!tw-bg-success-200",
      "dark:tw-bg-success-800",
      "dark:!tw-text-success-300",
      "dark:hover:!tw-bg-success-700",
      "dark:active:!tw-bg-success-600",
    );
  }

  if (props.color === "red") {
    classes.push(
      "tw-bg-red-300/70",
      "!tw-text-red-700",
      "hover:!tw-bg-red-300",
      "active:!tw-bg-red-200",
      "dark:tw-bg-red-800",
      "dark:!tw-text-red-300",
      "dark:hover:!tw-bg-red-700",
      "dark:active:!tw-bg-red-600",
    );
  }

  if (props.color === "orange") {
    classes.push(
      "tw-bg-orange-300/70",
      "!tw-text-orange-700",
      "hover:!tw-bg-orange-300",
      "active:!tw-bg-orange-200",
      "dark:tw-bg-orange-800",
      "dark:!tw-text-orange-300",
      "dark:hover:!tw-bg-orange-700",
      "dark:active:!tw-bg-orange-600",
    );
  }

  if (props.color === "purple") {
    classes.push(
      "tw-bg-purple-300",
      "dark:tw-bg-purple-900",
      "!tw-text-purple-800",
      "hover:!tw-bg-purple-300",
      "active:!tw-bg-purple-200",
      "dark:!tw-text-purple-300",
      "dark:hover:!tw-bg-purple-700",
      "dark:active:!tw-bg-purple-600",
    );
  }

  if (props.color === "gray") {
    classes.push(
      "tw-bg-zinc-400/70",
      "tw-text-zinc-700",
      "hover:tw-bg-zinc-400",
      "active:tw-bg-zinc-500",
      "dark:tw-bg-zinc-600",
      "dark:tw-text-zinc-300",
      "dark:hover:!tw-bg-zinc-500",
      "dark:active:!tw-bg-zinc-600",
    );
  }

  if (props.small) {
    classes.push("tw-text-xs");
  }

  if (props.large) {
    classes.push("!tw-p-4");
  }

  if (props.dense) {
    classes.push("!tw-p-1");
  }

  if (!props.wrap) {
    classes.push("tw-whitespace-nowrap");
  }

  return classes;
});

const effectiveClasses = computed(() => [
  "tw-text-current",
  "tw-rounded-md",
  "tw-cursor-pointer",
  "tw-transition-all",
  "tw-inline-flex",
  "tw-items-center",
  "tw-py-2",
  "tw-px-3",
  "tw-font-semibold",
  "tw-select-none",
  "tw-text-sm",
  ...dynamicClasses.value,
]);
</script>

<template>
  <RouterLink
    :class="effectiveClasses"
    :to="to"
    :target="target"
    role="button"
    v-bind="routerLinkProps"
    v-if="to"
  >
    <slot />
  </RouterLink>
  <a
    :href="href"
    :target="target"
    role="button"
    :class="effectiveClasses"
    @click="emit('click', $event)"
    v-else
  >
    <slot />
  </a>
</template>
