import { BaseAPIClient } from "./API";
import { Buyer, ForbiddenError } from "@/lib/OrgTypes";

export class BuyersClient extends BaseAPIClient {
  async getBuyer(buyerId: string): Promise<Buyer | null> {
    const url = `/buyers/${encodeURIComponent(buyerId)}`;
    const res = await this.safeRequest({
      url,
    });
    if (res.status === 404) {
      throw new Error("Buyer not found");
    }
    if (res.status === 403) {
      throw new ForbiddenError("Action forbidden");
    }
    return res.data;
  }
  async updateBuyer(
    buyerId: string,
    dataInput: Partial<Buyer>,
  ): Promise<Buyer | null> {
    const url = `/buyers/${encodeURIComponent(buyerId)}`;
    const res = await this.safeRequest({
      url,
      method: "PATCH",
      data: dataInput,
    });
    return res.data;
  }
}
