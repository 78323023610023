import { createGlobalState } from "@vueuse/core";
import { uniqueId } from "lodash-es";
import { computed, readonly, ref } from "vue";

const MAX_CONCURRENT_TOASTS = 5;

export type Action = {
  label: string;
  fn: () => void;
};

export type Toast = {
  level: "info" | "success" | "warning" | "error";
  message: string;
  persistent?: boolean;
  ttl?: number;
  action?: Action;
};

export type ToastWithId = {
  id: string;
} & Toast;

export const useToast = createGlobalState(function () {
  const toasts = ref<ToastWithId[]>([]);
  const lastToast = computed(() =>
    toasts.value.length > 0 ? toasts.value[toasts.value.length - 1] : null,
  );

  // Timeout is the number of seconds before the toast is removed
  function pushToast(toast: Toast, customId?: string, timeout?: number) {
    if (customId) {
      removeToast(customId);
    }
    const toast_id = customId ?? uniqueId("toast");
    const newToasts = [...toasts.value, { ...toast, id: toast_id }];
    if (newToasts.length > MAX_CONCURRENT_TOASTS) {
      newToasts.shift();
    }
    toasts.value = newToasts;
    if (timeout) {
      setTimeout(
        () => {
          removeToast(toast_id);
        },
        Math.ceil(timeout * 1000),
      );
    }
  }

  function removeToast(toastId: string) {
    toasts.value = toasts.value.filter(({ id }) => id !== toastId);
  }

  return {
    pushToast,
    removeToast,
    toasts: readonly(toasts),
    lastToast,
  };
});
