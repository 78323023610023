<script setup lang="ts">
import { computed } from "vue";
import { useToast } from "@/lib/useToast";

const props = withDefaults(
  defineProps<{
    halign?: "left" | "center" | "right";
    valign?: "top" | "bottom";
  }>(),
  {
    halign: "center",
    valign: "top",
  },
);

const { toasts, removeToast } = useToast();

const posClasses = computed(() => {
  const classes = [];
  if (props.halign === "left") {
    classes.push("tw-items-start");
  }
  if (props.halign === "center") {
    classes.push("tw-items-center");
  }
  if (props.halign === "right") {
    classes.push("tw-items-end");
  }
  if (props.valign === "top") {
    classes.push("tw-top-0", "tw-flex-col");
  }
  if (props.valign === "bottom") {
    classes.push("tw-bottom-0", "tw-flex-col-reverse");
  }
  return classes;
});
</script>

<template>
  <TransitionGroup
    appear
    css
    tag="div"
    class="tw-fixed tw-left-0 tw-p-4 tw-flex tw-w-screen tw-pointer-events-none tw-gap-2 tw-z-[1000]"
    :class="posClasses"
    enter-from-class="-tw-translate-x-full tw-opacity-0"
    enter-active-class="tw-ease-out tw-duration-300"
    enter-to-class="tw-translate-0 tw-opacity-100"
    leave-from-class="tw-translate-0 tw-opacity-100"
    leave-active-class="tw-ease-in tw-duration-200"
    leave-to-class="-tw-translate-x-full tw-opacity-0"
  >
    <t-toast
      class="tw-will-change-transform tw-pointer-events-auto tw-transition-all tw-duration-150 tw-w-min tw-max-w-full"
      :key="toast.id"
      :level="toast.level"
      :message="toast.message"
      :persistent="toast.persistent"
      :ttl="toast.ttl"
      :action="toast.action"
      @dismiss="removeToast(toast.id)"
      v-for="toast in toasts"
    />
  </TransitionGroup>
</template>
