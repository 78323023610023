import { BaseAPIClient } from "./API";
import { Provider } from "@/lib/OrgTypes";

export class ProvidersClient extends BaseAPIClient {
  async getProvider(providerId: string): Promise<Provider | null> {
    const url = `/providers/${encodeURIComponent(providerId)}`;
    const res = await this.safeRequest({
      url,
    });
    if (res.status === 404) {
      throw new Error("Provider not found");
    }
    return res.data;
  }
  async createProvider(organizationId: string): Promise<Provider | null> {
    const url = `/organizations/${encodeURIComponent(
      organizationId,
    )}/providers`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }
  async updateProvider(
    providerId: string,
    dataInput: Partial<Provider>,
  ): Promise<Provider | null> {
    const url = `/providers/${encodeURIComponent(providerId)}`;
    const res = await this.safeRequest({
      url,
      method: "PATCH",
      data: dataInput,
    });
    return res.data;
  }
  async updateProviderLogo(
    providerId: string,
    file: File,
  ): Promise<Provider | null> {
    const url = `/providers/${encodeURIComponent(providerId)}/logo`;
    const data = new FormData();

    data.append("file", file);
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    if (res.status === 404) {
      return null;
    }
    return res.data;
  }
}
