import { ref, watch } from "vue";
import { toRef, MaybeRefOrGetter } from "@vueuse/core";
import router from "../router";
import { useAsyncState } from "@vueuse/core";
import { getClient } from "./client";

import { Buyer } from "@/lib/OrgTypes";
import { BuyersClient } from "@/lib/BuyerAPI";

export function useBuyerProfile(buyerId: MaybeRefOrGetter<string | undefined>) {
  const reactiveBuyerId = toRef(buyerId);

  const buyer = ref<Buyer | undefined>();
  const initialLoadComplete = ref(false);
  const buyerLoading = ref(true);
  const buyerClient = new BuyersClient();

  const reloadBuyer = async () => {
    buyerLoading.value = true;
    try {
      if (!reactiveBuyerId.value) {
        buyer.value = undefined;
        return;
      }
      const maybeBuyer = await buyerClient.getBuyer(reactiveBuyerId.value);
      if (maybeBuyer) {
        buyer.value = maybeBuyer;
      }
      return buyer;
    } catch (e) {
      router.push({ name: "not-found" }).catch(() => {});
    } finally {
      buyerLoading.value = false;
      initialLoadComplete.value = true;
    }
  };

  watch(reactiveBuyerId, reloadBuyer, { immediate: true });

  return {
    buyer,
    initialLoadComplete,
    reloadBuyer,
    buyerLoading,
  };
}

export function isNonEmptyValue(value: any) {
  // This method controls whether or not we want to show that this field is
  // complete/has a valid answer to the user
  if (value === null) return false;

  // Since empty arrays are truthy, we need to check that it is not empty
  if (Array.isArray(value) && value.length === 0) return false;

  // We want to allow a user to input 0
  if (typeof value === "number" || typeof value === "boolean") return true;

  // This allows for attachments to be marked as complete
  return !!value;
}

export function useBuyerCompleteness() {
  function getPercentComplete(
    buyer: Buyer | undefined,
    fieldsToCheck: string[],
  ) {
    if (!buyer || fieldsToCheck.length === 0) {
      return 0;
    }

    const completedRequiredFields = fieldsToCheck.filter((field: string) =>
      isNonEmptyValue(buyer[field]),
    );
    return (completedRequiredFields.length / fieldsToCheck.length) * 100;
  }

  function percentComplete(
    buyer: Buyer | undefined,
    fields?: string[] | undefined,
  ) {
    const fieldsToCheck = fields || [];
    const rawPercentComplete = getPercentComplete(buyer, fieldsToCheck);
    return Math.round(rawPercentComplete);
  }

  function isComplete(buyer: Buyer | undefined) {
    return buyer ? percentComplete(buyer) === 100 : 0;
  }

  // pass in the specific fields you want checked if applicable
  // otherwise it will check all required fields
  function progressChipStatus(buyer: Buyer | undefined, fields?: string[]) {
    const pc = percentComplete(buyer, fields);
    if (!pc) {
      return "not-started";
    }
    if (pc === 100) {
      return "completed";
    } else if (pc < 100) {
      return "in-progress";
    } else {
      return "not-started";
    }
  }

  // unlike the provider profile, the buyer does not get a list of required fields from the AP
  const requiredFields = [
    "common_org_name",
    "legal_org_name",
    "industry_sector",
    "site_control_type",
    "specific_goals",
    "esg_strategy",
  ];

  return {
    percentComplete,
    isComplete,
    getPercentComplete,
    progressChipStatus,
    requiredFields,
  };
}

export function useBuyers() {
  const client = getClient();

  const {
    state: buyers,
    isReady: ready,
    isLoading: loading,
    error: error,
    execute: reloadBuyers,
  } = useAsyncState(
    async () => {
      return await client.get<Buyer[]>(`/buyers`).then((t) => t.data);
    },
    undefined,
    { resetOnExecute: false },
  );

  return {
    buyers,
    ready,
    loading,
    error,
    reloadBuyers,
  };
}
