import { computed } from "vue";
import { jwtDecode } from "jwt-decode";
import { useAccessToken } from "./useAccessToken";
import { useTheme } from "./useTheme";

import env from "../env";

// Sourced from https://github.com/StationA/stationa-api/blob/main/api/rbac.py
export type Role = "super" | "beta" | "user" | "buyer" | "provider";

// TODO: How can we combine these two type and constant definitions?
export const ROLES = ["super", "beta", "user", "buyer", "provider"];

export type JWT = {
  ver: string;
  jti: string;
  sub: string;
  iss: string;
  aud: string[] | string;
  exp: number;
  iat: number;
  account_id: string;
  roles: Role[];
  groups: string[] | undefined;
};

/**
 * Helper function to construct an accounts app URL
 */
export function getAccountsAppUrl(
  extraParams: Record<string, any> = {},
  endpoint: string = "/",
) {
  const url = new URL(endpoint, env.accountsBaseUrl);
  url.searchParams.set("redirect_url", window.location.href);
  url.searchParams.set("utm_source", "webapp");

  const { dark } = useTheme();
  url.searchParams.set("theme", dark.value ? "dark" : "light");
  for (const [k, v] of Object.entries(extraParams)) {
    url.searchParams.set(k, v);
  }
  return url.toString();
}

/**
 * Core composable for interacting with global user auth state
 */
export function useAuth() {
  const { accessToken } = useAccessToken();

  const jwtPayload = computed(() =>
    accessToken.value ? jwtDecode(accessToken.value) : null,
  );

  const jwt = computed(() => (jwtPayload.value ?? {}) as Partial<JWT>);

  const isAuthed = computed(() => !!jwt.value.account_id);

  const accountId = computed(() => {
    return jwt.value.account_id || null;
  });

  const roles = computed(() => {
    return jwt.value.roles || [];
  });

  const groups = computed(() => {
    return jwt.value.groups || [];
  });

  function hasRole(requirement: Role): boolean {
    return roles.value.includes(requirement);
  }

  const isSuper = computed(() => roles.value.includes("super"));

  const isBeta = computed(() => roles.value.includes("beta"));

  const organizationId = computed(() => {
    const orgGroups = groups.value.filter((g) =>
      g.startsWith("/organizations/"),
    );
    if (orgGroups.length > 0) {
      return orgGroups[0].split("/organizations/")[1];
    }
    return null;
  });

  const providerId = computed(() => {
    const providerGroups = groups.value.filter((g) =>
      g.startsWith("/providers/"),
    );
    if (providerGroups.length > 0) {
      return providerGroups[0].split("/providers/")[1];
    }
    return undefined;
  });

  const isProvider = computed(() => {
    return hasRole("provider");
  });

  const buyerId = computed(() => {
    const buyerGroups = groups.value.filter((g) => g.startsWith("/buyers/"));
    if (buyerGroups.length > 0) {
      return buyerGroups[0].split("/buyers/")[1];
    }
    return undefined;
  });

  const isBuyer = computed(() => {
    return hasRole("buyer");
  });

  const userType = computed(() => {
    if (isBuyer.value) return "buyer";
    if (isProvider.value) return "provider";
    return null;
  });

  const hasOrg = computed(() => {
    return isBuyer.value || isProvider.value;
  });

  return {
    isAuthed,
    jwt,
    groups,
    roles,
    hasRole,
    isBeta,
    isBuyer,
    isProvider,
    isSuper,
    userType,
    hasOrg,
    accountId,
    organizationId,
    buyerId,
    providerId,
  };
}
