import { getClient } from "./client";

export class BaseAPIClient {
  async getSchemaByName(dataType) {
    const url = `/${dataType}/_schema`;
    const res = await this.safeRequest({ url });
    return res.data;
  }

  async safeRequest(request) {
    try {
      return await getClient().request(request);
    } catch (error) {
      // From https://github.com/axios/axios#handling-errors
      // TODO: This could still use some deeper refactoring, but it's a little sensitive to larger
      // changes at the moment
      if (error.response) {
        const status = error.response.status;
        const errorDetails =
          error.response.data && error.response.data._error
            ? error.response.data._error.message
            : `${error}`;
        if (status === 400 || status >= 500) {
          const errorType = status === 400 ? `Bad API request` : `Server error`;
          throw new Error(`${errorType} (${status}): ${errorDetails}`);
        } else if (status === 401) {
          throw new Error(`Authentication error (${status}): ${errorDetails}`);
        } else {
          return error.response;
        }
      } else if (error.request) {
        throw new Error(`No API response received (client): ${error}`);
      } else {
        throw new Error(`Couldn't make request (client): ${error}`);
      }
    }
  }
}

export class OrganizationClient extends BaseAPIClient {
  async create() {
    const url = `/organizations`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }
  async createProvider(organizationId) {
    const url = `/organizations/${encodeURIComponent(
      organizationId,
    )}/providers`;
    const res = await this.safeRequest({
      url,
      method: "POST",
      data: {},
    });
    return res.data;
  }
  async createBuyer(organizationId) {
    const url = `/organizations/${encodeURIComponent(organizationId)}/buyers`;
    const res = await this.safeRequest({
      url,
      method: "POST",
      data: {},
    });
    return res.data;
  }
  async joinOrg(inviteToken) {
    const url = `/organizations/join`;
    const data = {
      invite_token: inviteToken,
    };
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    if (res.status != 200) {
      return null;
    }
    return res.data;
  }
}

export class AccountClient extends BaseAPIClient {
  async getSchema() {
    return await this.getSchemaByName("accounts");
  }
  async getSummary() {
    const url = "/account/summary";
    const res = await this.safeRequest({ url });
    return res.data;
  }
  async getProfile() {
    const url = "/account/profile";
    const res = await this.safeRequest({ url });
    return res.data;
  }
  async getPreferences() {
    const url = "/account/preferences";
    const res = await this.safeRequest({ url });
    return res.data;
  }
  async updateProfile(dataInput) {
    const url = "/account/profile";
    const data = {
      first_name: dataInput.firstName,
      last_name: dataInput.lastName,
      email: dataInput.email,
      phone: dataInput.phone,
      company: dataInput.company,
      job_title: dataInput.jobTitle,
    };
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    return res.data;
  }
  async updatePreferences(dataInput) {
    const url = "/account/preferences";
    const data = {
      product_newsletter: dataInput.productNewsletter,
      beta_program: dataInput.betaProgram,
    };
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    return res.data;
  }
  async logout() {
    const url = "/auth/logout";
    await this.safeRequest({
      url,
      method: "POST",
      withCredentials: true,
    });
  }
}

export class LinkClient extends BaseAPIClient {
  async autocomplete(types, q = null, size = undefined, criteria = {}) {
    const url = "/link/autocomplete";
    const data = {
      types,
      q,
      size,
      criteria,
    };
    const res = await this.safeRequest({ method: "POST", url, data });
    return res.data;
  }
  async searchAddress(q = null, size = undefined) {
    const url = "/link/address_search";
    const data = {
      q,
      size,
    };
    const res = await this.safeRequest({ method: "POST", url, data });
    return res.data;
  }
  async resolve(ref) {
    const url = "/link/resolve";
    const params = { ref };
    const res = await this.safeRequest({ url, params });
    return {
      changed: res.data.changed,
      sameAs: res.data.same_as,
    };
  }
}

export class RFIsClient extends BaseAPIClient {
  async list(rfpId = null, isUnanswered = null) {
    const url = `/rfis/list`;
    const params = {
      rfp_id: rfpId,
      is_unanswered: isUnanswered,
    };
    const res = await this.safeRequest({
      url,
      params,
    });
    if (res.status === 404) {
      return null;
    }
    return res.data;
  }
  async get(rfiId) {
    const url = `/rfis/${encodeURIComponent(rfiId)}`;
    const res = await this.safeRequest({
      url,
    });
    if (res.status === 404) {
      return null;
    }
    return res.data;
  }
  async create(rfpId, question) {
    const url = "/rfis/create";
    const data = {
      rfp_id: rfpId,
      question: question,
    };
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    return res.data;
  }
}
